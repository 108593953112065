<template>
    <v-card :loading="loading">
        <v-card-title>
            <v-btn small icon @click="close" color="primary" class="mr-2">
                <v-icon>mdi-close</v-icon>
            </v-btn>
            <h3>{{ title }}</h3>
            <v-spacer />
            <v-btn
                color="primary"
                class="my-2"
                rounded
                :loading="loading"
                :disabled="!valid || Object.keys(milestoneDiff).length < 2"
                @click="saveChanges"
            >
                SAVE
            </v-btn>
        </v-card-title>

        <v-divider class="ml-4 mr-5" />

        <v-card-text
            :class="{
                'height-sm': $vuetify.breakpoint.smAndDown,
                'height-md': !$vuetify.breakpoint.smAndDown,
            }"
            style="overflow-y: auto;"
        >
            <v-row cols="12">
                <v-col cols="12" md="6">
                    <v-form v-model="valid" class="mt-2 mb-0">
                        <v-row no-gutters cols="12" class="mt-6">
                            <v-col cols="12" sm="3" class="mx-0 mt-3">
                                <v-menu
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                    v-model="menu2"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="milestone.date"
                                            label="Milestone Date*"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            required
                                            hide-details
                                            class="pt-0"
                                            :rules="[rules.required]"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="milestone.date"
                                        @input="menu2 = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="9"
                                class="ma-0 d-flex align-end"
                            >
                                <v-slider
                                    step="5"
                                    show-ticks="always"
                                    thick-size="4"
                                    :min="0"
                                    :max="100"
                                    :color="
                                        componentColor(milestone.percentage)
                                    "
                                    v-model="milestone.percentage"
                                    thumb-label="always"
                                    class="ma-0 ml-3"
                                    hide-details
                                    label="Percentage"
                                    :thumb-size="28"
                                    :class="{
                                        'mt-12': $vuetify.breakpoint.smAndDown,
                                        'mt-0': !$vuetify.breakpoint.smAndDown,
                                    }"
                                ></v-slider>
                            </v-col>
                        </v-row>
                        <v-row class="d-flex flex-wrap mt-5 mx-1 align-center">
                            <h4 class="mt-2">
                                Files:
                            </h4>
                            <div
                                v-if="
                                    milestone.files &&
                                        milestone.files.length > 0
                                "
                            >
                                <v-btn
                                    color="transparent"
                                    elevation="0"
                                    small
                                    class="ma-1 text-capitalize text--darken-2  pa-2 "
                                    v-for="(item, i) in localFiles.map(
                                        localFile => localFile.file.name
                                    ) || []"
                                    :key="i"
                                >
                                    <span
                                        class="col-12 text-truncate"
                                        @click.stop="openFile(item)"
                                        style="font-size: 11px; color: #2b81d6; text-decoration: underline; cursor: pointer; max-width: 65vw ;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                                        >{{ item }}</span
                                    >
                                    <v-icon
                                        @click.stop="deleteFile(item)"
                                        class="ml-1"
                                        small
                                    >
                                        mdi-close-circle
                                    </v-icon>
                                </v-btn>
                            </div>
                            <v-btn
                                class="mx-4"
                                depressed
                                fab
                                x-small
                                color="primary"
                                @click="onButtonClick"
                                :disabled="loading"
                                style="width: 20px; height: 20px;"
                            >
                                <v-icon>mdi-plus</v-icon>
                            </v-btn>
                            <input
                                ref="milestoneFiles"
                                class="d-none"
                                type="file"
                                name="milestoneFiles"
                                @change="onFileChanged"
                            />
                        </v-row>
                        <v-row no-gutters cols="12" class="mt-6">
                            <v-textarea
                                v-model="milestone.notes"
                                class="mx-0"
                                hide-details
                                prepend-icon="mdi-text"
                                label="Notes *"
                                :rules="[rules.required]"
                                required
                                rows="2"
                            />
                        </v-row>
                    </v-form>
                </v-col>
                <v-col
                    cols="12"
                    md="6"
                    class="mt-3"
                    style="overflow-y: auto;"
                    :class="{
                        'timeline-sm': $vuetify.breakpoint.smAndDown,
                        'timeline-md': !$vuetify.breakpoint.smAndDown,
                    }"
                >
                    <v-timeline dense class="pr-6" align-top>
                        <div
                            v-for="(item, index) in milestone.timeline || []"
                            :key="index"
                        >
                            <v-timeline-item
                                small
                                :color="componentColor(item.percentage)"
                            >
                                <v-row justify="space-between">
                                    <v-col cols="12" class="pa-0">
                                        <v-divider class="pa-0 mt-6 ml-n6" />
                                    </v-col>

                                    <v-col cols="12" sm="4">
                                        <span
                                            class="text-capitalize font-weight-bold"
                                        >
                                            {{ item.percentage }} %
                                        </span>
                                        <p class="my-0 py-0">
                                            {{ getUserName(item.updatedBy) }}
                                        </p>
                                        <p class="my-0 py-0">
                                            {{
                                                formatDate(
                                                    item.updatedOn._seconds ||
                                                        item.updatedOn.seconds
                                                )
                                            }}
                                        </p>
                                    </v-col>
                                    <v-col class="text-left" cols="12" sm="8">
                                        <p>
                                            <span class="font-weight-bold"
                                                >Notes: </span
                                            >{{ item.notes }}
                                        </p>
                                        <p>
                                            <span class="font-weight-bold"
                                                >Milestone Date:
                                            </span>
                                            {{
                                                formatDate2(
                                                    item.date._seconds ||
                                                        item.date.seconds
                                                )
                                            }}
                                        </p>
                                        <div
                                            v-if="
                                                milestone.files &&
                                                    milestone.files.length > 0
                                            "
                                        >
                                            <v-btn
                                                color="transparent"
                                                elevation="0"
                                                small
                                                class="ma-1 text-capitalize text--darken-2  py-2 px-0 "
                                                v-for="(file,
                                                i) in timelineFiles(
                                                    milestone.timeline,
                                                    index
                                                ) || []"
                                                :key="i"
                                            >
                                                <span
                                                    class="col-12 text-truncate px-0 pt-0"
                                                    @click.stop="openFile(file)"
                                                    style="font-size: 11px; color: #2b81d6; text-decoration: underline; cursor: pointer; max-width: 20vw ;overflow: hidden; text-overflow: ellipsis; white-space: nowrap;"
                                                    >{{ file }}</span
                                                >
                                            </v-btn>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-timeline-item>
                        </div>
                    </v-timeline>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import { objDiff } from '@/helpers/objDiff.js'
import _ from 'lodash'
import API from '@/services/api'
import moment from 'moment'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { deleteFile } from '@/services/storage/deleteFile.js'

export default {
    name: 'MilestoneDetails',
    props: {
        selectedMilestone: {
            type: String,
            default: () => '',
            required: true,
        },
        originalCost: {
            type: Object,
            default: () => ({}),
            required: true,
        },
        milestones: {
            type: Array,
            default: () => [],
            required: true,
        },
        settingId: {
            type: String,
            default: () => '',
            required: true,
        },
        users: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        rules: {
            required: v => !!v || 'The value is required',
        },
        originalMilestone: {},
        milestone: {},
        title: 'Scope ',
        menu2: false,
        localFiles: [],
        companyId: JSON.parse(localStorage.getItem('company')),
    }),
    computed: {
        milestoneDiff: function() {
            return objDiff(this.originalMilestone, this.milestone)
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.title += this.originalCost.reference
            const foundMilestone = this.milestones.find(
                m => m.id == this.selectedMilestone
            )
            if (foundMilestone) {
                this.title += ` - ${foundMilestone.name}`
            }
            if (
                this.originalCost &&
                this.originalCost.formatMilestones[this.selectedMilestone]
            ) {
                this.originalMilestone = _.cloneDeep(
                    this.originalCost.formatMilestones[this.selectedMilestone]
                )
                this.milestone = _.cloneDeep(
                    this.originalCost.formatMilestones[this.selectedMilestone]
                )
                delete this.milestone.notes
                delete this.originalMilestone.notes
            }

            if (this.milestone.date) {
                this.originalMilestone.date = this.formatDate2(
                    this.milestone.date._seconds || this.milestone.date.seconds
                )
                this.milestone.date = this.formatDate2(
                    this.milestone.date._seconds || this.milestone.date.seconds
                )
            }

            if (this.milestone.timeline) {
                this.milestone.timeline = this.milestone.timeline.reverse()
            }
        } catch (error) {
            this.setErrorItems({
                name: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getUserName(userId) {
            let name = ''
            const user = this.users.find(u => u.id == userId)
            if (user) {
                name = user.name
            }
            return name
        },
        formatDate(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM-DD HH:mm')}`
        },
        formatDate2(seconds) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        timelineDiff(milestone, index) {
            const timeline = milestone.timeline
            let prevChanges = {}
            if (index < milestone.timeline.length - 1) {
                prevChanges = timeline[index + 1]
            }

            const changes = objDiff(prevChanges, timeline[index])
            delete changes.updatedOn
            delete changes.updatedBy
            delete changes.notes
            let text = Object.keys(changes)
                .map(key => {
                    const label = key === 'date' ? 'Milestone Date' : key
                    return label
                        .split(' ')
                        .map(
                            word => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(' ')
                })
                .join(', ')
            return text
        },
        async deleteFile(file) {
            try {
                this.loading = true
                const localIndex = this.localFiles.findIndex(
                    localFile => localFile.file.name == file
                )
                if (localIndex > -1) {
                    this.localFiles.splice(localIndex, 1)
                } else {
                    await deleteFile(
                        file,
                        `${this.companyId}/quotes/${this.originalCost.quoteId}/costs/${this.originalCost.id}`
                    )
                }
                const fileIndex = this.milestone.files.findIndex(f => f == file)
                this.milestone.files.splice(fileIndex, 1)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async saveFile(file) {
            try {
                this.loading = true
                await saveFile(
                    file,
                    `${this.companyId}/quotes/${this.originalCost.quoteId}/costs/${this.originalCost.id}`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async onFileChanged(e) {
            try {
                this.loading = true
                this.milestone.files = this.milestone.files || []
                if (e.target.files.length > 0) {
                    let file = e.target.files[0]
                    const fileName =
                        moment().format('YYYY-MM-DD HH:mm ') + file.name
                    file = new File([file], fileName, {
                        type: file.type,
                    })
                    this.milestone.files.push(fileName)
                    this.localFiles = [
                        ...this.localFiles,
                        {
                            url: URL.createObjectURL(file),
                            file,
                        },
                    ]
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        onButtonClick() {
            this.$refs.milestoneFiles.click()
        },
        async openFile(fileName) {
            try {
                this.loading = true
                const localFile = this.localFiles.find(
                    localFile => localFile.file.name == fileName
                )
                if (localFile) {
                    window.open(localFile.url, '_blank')
                } else {
                    await openFile(
                        fileName,
                        `${this.companyId}/quotes/${this.originalCost.quoteId}/costs/${this.originalCost.id}`
                    )
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
        componentColor(value) {
            if (!value || value == 0) return 'error'
            if (value < 100) return 'primary'
            return 'success'
        },
        async saveChanges() {
            try {
                this.loading = true
                if (this.localFiles && this.localFiles.length > 0) {
                    for (const localFile of this.localFiles) {
                        await this.saveFile(localFile.file)
                    }
                }
                if (this.milestoneDiff.files) {
                    this.milestoneDiff.files = this.milestone.files
                }
                delete this.milestoneDiff.timeline
                const updateParams = {
                    quoteId: this.originalCost.quoteId,
                    costId: this.originalCost.id,
                    milestoneId: this.selectedMilestone,
                    settingId: this.settingId,
                    ...this.milestoneDiff,
                }
                const updatedMilestones = await API.updateCostMilestone(
                    updateParams
                )
                this.$emit('replaceMilestones', {
                    costId: this.originalCost.id,
                    milestones: updatedMilestones,
                })
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        timelineFiles(timeline, index) {
            let prevFiles = []
            if (index < timeline.length - 1) {
                prevFiles = timeline[index + 1].files || []
            }
            const currentFiles = timeline[index].files || []
            const files = currentFiles.filter(file => !prevFiles.includes(file))
            return files
        },
    },
}
</script>

<style>
.timeline-sm {
    max-height: none;
}

.timeline-md {
    max-height: calc(100vh - 200px);
}

.height-sm {
    max-height: calc(100vh - 200px);
}

.height-md {
    max-height: calc(100vh - 100px);
}
</style>
